.App {
  text-align: center;
  background-color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logos .slick-slider {
  width: 100%;
  overflow: hidden;
}
.logos .slick-list {
  position: relative;
  display: block !important;
  width: 100%;
}

.logos .slick-list .slick-track {
  justify-content: space-around;
}

.logos .slick-slide div {
  width: auto;
  margin-right: 10px;
}

/* .logos .slick-slide img {
  width: fit-content !important;
  height: fit-content;
} */

.logos .slick-track {
  display: flex !important;
  gap: 35px;
  align-items: center;
}

/* 
.flip-card {
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
} */

/* .card-rotate {
  transform: rotateY(180deg);
} */

/* .flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
} */

/* .flip-card-back {
  color: white;
  transform: rotateY(180deg);
} */

.flip-card .slick-list .slick-track {
  display: flex;
  justify-content: center;
  width: fit-content !important;
}

.notification-container {
  top: 50px !important;
}

@media only screen and (max-width: 640px) {
  .overlay {
    position:sticky;
    position:-webkit-sticky;
    top: 0;
  }

}

